.filterBtn {
    background: var(--background-color);
    border: none;
    outline: none;
    min-width: 90px;
    color: #fff;
    border-radius: 1.5rem;
    padding: 0.5rem 1rem;
    font-weight: 700;
}

.filterBtn.selected {
    background: #12b8ff;
}

.searchWrapper {
    position: relative;
}

.searchIcon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;
    height: 15px;
}

.search {
    background: var(--background-color);
    outline: none;
    border: none;
    color: #fff;
    border-radius: 1.5rem;
    padding: 0.5rem 2.5rem 0.5rem 0.75rem;
    width: 150px;
    transition: width ease 0.3s;
}

.search.focus {
    width: 200px;
}

.fileWrapper {
    background-image: url(../assets/image/video-bg.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    padding: 10.2%;
}

.voteWrapper {
    padding: 0 10%;
    margin-top: -5%;
}

.voteIconWrapper {
    background-color: rgb(255 255 255 / 0.15);
    border-radius: 50%;
    padding: 10px;
    width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.voteIconWrapper.voted {
    background-color: var(--background-color);
}

.voteIcon {
    height: 30px;
}

.signInText {
    font-size: 0.775rem;
}

.voteCountIcon {
    width: 25px;
    margin-right: 0.5rem;
}

.voteCount {
    background-color: #0e678d80;
    padding: 3px 9px;
    border-radius: 0.65rem;
    font-size: 1.2rem;
    font-weight: 500;
}

.submissionInfo {
    padding: 0 10%;
    margin-top: 4%;
}

.submissionInfoField {
    background-color: var(--background-color);
    border-radius: 1rem;
    padding: 0.7rem 1rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.submissionDescription {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-bottom: 2.5rem;
    font-size: 0.9rem;
    text-align: justify;
}

.submissionVote {
    padding: 0 10%;
}

.signOutBtn {
    position: fixed;
    right: 3%;
    bottom: 5%;
    width: 60px;
    height: 60px;
    background-color: var(--background-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 10px;
    z-index: 20;
}

.signOutBtnIcon {
    width: 25px;
}