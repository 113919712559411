.filePreview {
    width: 100%;
    display: block;
    background-color: #000;
    aspect-ratio: 16/9;
    object-fit: contain;
    height: 100%;
    overflow: auto;
}

.filePreview.audio {
    padding-bottom: 20%;
}

.zoomBtn {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 40px;
    height: 40px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(0 0 0 / 0.5);
    border-radius: 0.5rem;
    z-index: 10;
}

.zoomWrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    padding: 5%;
    top: 0;
    left: 0;
    background-color: rgb(0 0 0 / 0.7);
    z-index: 100;
}

.zoomWrapper .filePreview {
    background-color: transparent;
}

.closeZoomBtn {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(200 200 200 / 0.3);
}