.box {
    background-color: var(--background-color);
    padding: 2rem;
    border-radius: 1.8rem;
}

.box.square {
    border-radius: 0;
}

.box.small {
    padding: 1rem;
}