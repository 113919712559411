.checkSvg {
    width: 70px;
    display: block;
    margin: auto;
    margin-bottom: 1.5rem;
}

.footerArea {
    padding-top: 10vh;
    padding-bottom: 5vh;
    width: 100%;
    text-align: center;
    position: relative;
}

.footerAreaText {
    position: relative;
    z-index: 10;
}

.submitAreaBg {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 60vh;
    height: 100%;
    object-fit: cover;
}

.imgProjectGallery {
    display: block;
    width: 100%;
    max-width: 300px;
    margin: auto;
}

.unlockTheFutureSvg {
    display: block;
    width: 100%;
    max-width: 600px;
    margin: auto;
}