.wrapper {
    border-radius: 0.65rem;
    border: none;
    outline: none;
}

.label {
    font-weight: 500;
    white-space: pre;
}

.input {
    background: var(--background-color);
    border: none;
    border-radius: 0.65rem;
    outline: none;
    width: 100%;
    text-align: center;
}

.input::placeholder {
    color: #fff;
}

.register .input {
    height: 2.3rem;
}

.textarea {
    background: var(--background-color);
    border: none;
    border-radius: 0.65rem;
    outline: none;
    width: 100%;
}

/* width */
.textarea::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.textarea::-webkit-scrollbar-track {
    background: #f1f1f134;
    border-radius: 0.65rem;
}

/* Handle */
.textarea::-webkit-scrollbar-thumb {
    background: #89e91b;
    border-radius: 0.65rem;
}

/* Handle on hover */
.textarea::-webkit-scrollbar-thumb:hover {
    background: #5ca508;
}

.input option {
    background: var(--background-color);
}

.selected {
    background: var(--background-color);
}

.entryDelete {
    background-color: rgb(0 0 0 / 0.1);
    width: 30px;
    height: 30px;
    border-radius: 0.65rem;
}

.selected .entryDelete {
    background-color: rgb(0 0 0 / 0.2);
}

.submitArea {
    padding-top: 10vh;
    padding-bottom: 5vh;
    width: 100%;
    text-align: center;
    position: relative;
}

.submitAreaText {
    position: relative;
    z-index: 10;
}

.submitAreaBg {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 60vh;
    height: 100%;
    object-fit: cover;
}

.submitBtn {
    background: none;
    border: none;
    outline: none;
}

.continueSvg {
    display: block;
    width: 100%;
    max-width: 300px;
}

.unlockTheFutureSvg {
    display: block;
    width: 100%;
    max-width: 500px;
    margin: auto;
}